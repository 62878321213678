import React from 'react';
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const Datenschutz = () => {
  return (
    <article className="wrapper">
      <NavBar current="contact"/>
      <div className="mx-auto lg:text-left lg:w-3/4 mt-8 px-4 md:px-6 lg:px-8 max-w-screen-xl pb-28">
        <h1 className="text-2xl">Datenschutzerklärung</h1>

        <h2 className="text-xl mt-4"  >Inhaltsverzeichnis</h2>
        <ul className="list-disc">
          <li>
            <a href="#einleitung-ueberblick">Einleitung und Überblick</a>
          </li>
          <li>
            <a href="#anwendungsbereich">Anwendungsbereich</a>
          </li>
          <li>
            <a href="#rechtsgrundlagen">Rechtsgrundlagen</a>
          </li>
          <li>
            <a href="#kontaktdaten-verantwortliche">Kontaktdaten des Verantwortlichen</a>
          </li>
          <li>
            <a href="#speicherdauer">Speicherdauer</a>
          </li>
          <li>
            <a href="#rechte-dsgvo">Rechte laut Datenschutz-Grundverordnung</a>
          </li>
          <li>
            <a href="#datenuebertragung-drittlaender">Datenübertragung in Drittländer</a>
          </li>
          <li>
            <a href="#sicherheit-datenverarbeitung">Sicherheit der Datenverarbeitung</a>
          </li>
          <li>
            <a href="#kommunikation">Kommunikation</a>
          </li>
          <li>
            <a href="#cookies">Cookies</a>
          </li>
          <li>
            <a href="#webhosting">Webhosting</a>
          </li>
          <li>
            <a href="#content-delivery-networks">Content Delivery Networks</a>
          </li>
          <li>
            <a href="#benutzerdefinierte-google-suche-datenschutzerklaerung">Benutzerdefinierte Google Suche
              Datenschutzerklärung</a>
          </li>
          <li>
            <a href="#font-awesome-datenschutzerklaerung">Font Awesome Datenschutzerklärung</a>
          </li>
          <li>
            <a href="#google-fonts-datenschutzerklaerung">Google Fonts Datenschutzerklärung</a>
          </li>
        </ul>
        <h2 className="text-xl mt-4"   id="einleitung-ueberblick" >Einleitung und Überblick</h2>
        <p>Wir haben diese Datenschutzerklärung (Fassung 30.11.2021-121890947) verfasst, um Ihnen gemäß der Vorgaben
          der <a className="adsimple-121890947"
                 href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=121890947"
                 target="_blank" rel="noopener">Datenschutz-Grundverordnung (EU) 2016/679</a> und anwendbaren nationalen
          Gesetzen zu erklären, welche personenbezogenen Daten (kurz Daten) wir als Verantwortliche &#8211; und die von
          uns beauftragten Auftragsverarbeiter (z. B. Provider) &#8211; verarbeiten, zukünftig verarbeiten werden und
          welche rechtmäßigen Möglichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu
          verstehen.<br/>
          <strong >Kurz gesagt:</strong> Wir informieren Sie umfassend über Daten, die wir
          über Sie verarbeiten.</p>
        <p>Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische Fachbegriffe. Diese
          Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie möglich
          beschreiben. Soweit es der Transparenz förderlich ist, werden technische <strong
            >Begriffe leserfreundlich erklärt</strong>, <strong
            >Links</strong> zu weiterführenden Informationen geboten und <strong
            >Grafiken</strong> zum Einsatz gebracht. Wir informieren damit in klarer und
          einfacher Sprache, dass wir im Rahmen unserer Geschäftstätigkeiten nur dann personenbezogene Daten
          verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich, wenn man
          möglichst knappe, unklare und juristisch-technische Erklärungen abgibt, so wie sie im Internet oft Standard
          sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen interessant und
          informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.<br/>
          Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im Impressum genannte
          verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere Informationen auf
          Drittseiten anzusehen. Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.</p>
        <h2 className="text-xl mt-4"   id="anwendungsbereich" >Anwendungsbereich</h2>
        <p>Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten personenbezogenen Daten und für
          alle personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit
          personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name,
          E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt dafür,
          dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen können, sei es online oder offline. Der
          Anwendungsbereich dieser Datenschutzerklärung umfasst:</p>
        <ul >
          <li >alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
          <li >Social Media Auftritte und E-Mail-Kommunikation</li>
          <li >mobile Apps für Smartphones und andere Geräte</li>
        </ul>
        <p>
          <strong >Kurz gesagt:</strong> Die Datenschutzerklärung gilt für alle Bereiche,
          in denen personenbezogene Daten im Unternehmen über die genannten Kanäle strukturiert verarbeitet werden.
          Sollten wir außerhalb dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie gegebenenfalls
          gesondert informieren.</p>
        <h2 className="text-xl mt-4"   id="rechtsgrundlagen" >Rechtsgrundlagen</h2>
        <p>In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den rechtlichen
          Grundsätzen und Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns ermöglichen,
          personenbezogene Daten zu verarbeiten.<br/>
          Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS UND
          DES RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung der EU können Sie selbstverständlich online
          auf EUR-Lex, dem Zugang zum EU-Recht, unter <a className="adsimple-121890947"
                                                         href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=121890947"
                                                         target="_blank"
                                                         rel="noopener">https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=celex%3A32016R0679</a> nachlesen.
        </p>
        <p>Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p>
        <ol>
          <li >
            <strong >Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben
            uns Ihre Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre die
            Speicherung Ihrer eingegebenen Daten eines Kontaktformulars.
          </li>
          <li >
            <strong >Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag
            oder vorvertragliche Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum
            Beispiel einen Kaufvertrag mit Ihnen abschließen, benötigen wir vorab personenbezogene Informationen.
          </li>
          <li >
            <strong >Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c DSGVO):
            Wenn wir einer rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind wir
            gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese enthalten in der Regel
            personenbezogene Daten.
          </li>
          <li >
            <strong >Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f DSGVO): Im
            Falle berechtigter Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die Verarbeitung
            personenbezogener Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um unsere Website sicher und
            wirtschaftlich effizient betreiben zu können. Diese Verarbeitung ist somit ein berechtigtes Interesse.
          </li>
        </ol>
        <p>Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und Ausübung öffentlicher
          Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit eine solche
          Rechtsgrundlage doch einschlägig sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.</p>
        <p>Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
        <ul >
          <li >In <strong >Österreich</strong> ist dies das
            Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten (<strong
              >Datenschutzgesetz</strong>), kurz <strong
              >DSG</strong>.
          </li>
          <li >In <strong >Deutschland</strong> gilt
            das <strong >Bundesdatenschutzgesetz</strong>, kurz<strong
              > BDSG</strong>.
          </li>
        </ul>
        <p>Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in den folgenden
          Abschnitten darüber.</p>
        <h2 className="text-xl mt-4"   id="kontaktdaten-verantwortliche" >Kontaktdaten des Verantwortlichen</h2>
        <p>Sollten Sie Fragen zum Datenschutz haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person
          bzw. Stelle:<br/>
          <span >
</span>
        </p>
        <p>Impressum: <a
          href="https://www.mobility-leasing.at/impressum/">https://www.mobility-leasing.at/impressum/</a>
        </p>
        <h2 className="text-xl mt-4"   id="speicherdauer" >Speicherdauer</h2>
        <p>Dass wir personenbezogene Daten nur so lange speichern, wie es für die Bereitstellung unserer
          Dienstleistungen und Produkte unbedingt notwendig ist, gilt als generelles Kriterium bei uns. Das bedeutet,
          dass wir personenbezogene Daten löschen, sobald der Grund für die Datenverarbeitung nicht mehr vorhanden ist.
          In einigen Fällen sind wir gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des ursprüngliches
          Zwecks zu speichern, zum Beispiel zu Zwecken der Buchführung.</p>
        <p>Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur Datenverarbeitung widerrufen, werden
          die Daten so rasch wie möglich und soweit keine Pflicht zur Speicherung besteht, gelöscht.</p>
        <p>Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere
          Informationen dazu haben.</p>
        <h2 className="text-xl mt-4"   id="rechte-dsgvo" >Rechte laut Datenschutz-Grundverordnung</h2>
        <p>Laut Artikel 13 DSGVO stehen Ihnen die folgenden Rechte zu, damit es zu einer fairen und transparenten
          Verarbeitung von Daten kommt:</p>
        <ul >
          <li >Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten
            von Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die
            folgenden Informationen zu erfahren:
            <ul >
              <li >zu welchem Zweck wir die Verarbeitung durchführen;</li>
              <li >die Kategorien, also die Arten von Daten, die verarbeitet werden;</li>
              <li >wer diese Daten erhält und wenn die Daten an Drittländer übermittelt
                werden, wie die Sicherheit garantiert werden kann;
              </li>
              <li >wie lange die Daten gespeichert werden;</li>
              <li >das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung
                der Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;
              </li>
              <li >dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu
                diesen Behörden finden Sie weiter unten);
              </li>
              <li >die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;
              </li>
              <li >ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet
                werden, um zu einem persönlichen Profil von Ihnen zu gelangen.
              </li>
            </ul>
          </li>
          <li >Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was
            bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler finden.
          </li>
          <li >Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf
            Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung Ihrer Daten verlangen dürfen.
          </li>
          <li >Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der
            Verarbeitung, was bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiter verwenden.
          </li>
          <li >Sie haben laut Artikel 19 DSGVO das Recht auf Datenübertragbarkeit, was
            bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen Format zur Verfügung stellen.
          </li>
          <li >Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach
            Durchsetzung eine Änderung der Verarbeitung mit sich bringt.
            <ul >
              <li >Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e
                (öffentliches Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes
                Interesse) basiert, können Sie gegen die Verarbeitung Widerspruch einlegen. Wir prüfen danach so rasch
                wie möglich, ob wir diesem Widerspruch rechtlich nachkommen können.
              </li>
              <li >Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie
                jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr
                für Direktmarketing verwenden.
              </li>
              <li >Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit
                gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für
                Profiling verwenden.
              </li>
            </ul>
          </li>
          <li >Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer
            ausschließlich auf einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung
            unterworfen zu werden.
          </li>
        </ul>
        <p>
          <strong >Kurz gesagt:</strong> Sie haben Rechte &#8211; zögern Sie nicht, die
          oben gelistete verantwortliche Stelle bei uns zu kontaktieren!</p>
        <p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
          datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind, können Sie sich bei der
          Aufsichtsbehörde beschweren. Diese ist für Österreich die Datenschutzbehörde, deren Website Sie unter <a
            className="adsimple-121890947" href="https://www.dsb.gv.at/?tid=121890947" target="_blank"
            rel="noopener">https://www.dsb.gv.at/</a> finden. In Deutschland gibt es für jedes Bundesland einen
          Datenschutzbeauftragten. Für nähere Informationen können Sie sich an die <a className="adsimple-121890947"
                                                                                      href="https://www.bfdi.bund.de"
                                                                                      target="_blank" rel="noopener">Bundesbeauftragte
            für den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden. Für unser Unternehmen ist die folgende
          lokale Datenschutzbehörde zuständig:</p>
        <h2 className="text-xl mt-4"   id="oesterreich-datenschutzbehoerde" >Österreich Datenschutzbehörde</h2>
        <p>
          <strong >Leiterin: </strong>Mag. Dr. Andrea Jelinek<strong
          >
          <br/>
          Adresse: </strong>Barichgasse 40-42, 1030 Wien<strong >
          <br/>
          Telefonnr.: </strong>+43 1 52 152-0<strong >
          <br/>
          E-Mail-Adresse: </strong>
          <a >dsb@dsb.gv.at</a>
          <strong >
            <br/>
            Website: </strong>
          <a className="adsimple-121890947" href="https://www.dsb.gv.at/" target="_blank"
             rel="noopener">https://www.dsb.gv.at/</a>
        </p>
        <h2 className="text-xl mt-4"   id="datenuebertragung-drittlaender" >Datenübertragung in Drittländer</h2>
        <p>Wir übertragen oder verarbeiten Daten nur dann in Länder außerhalb der EU (Drittländer), wenn Sie dieser
          Verarbeitung zustimmen, dies gesetzlich vorgeschrieben ist oder vertraglich notwendig und in jedem Fall nur
          soweit dies generell erlaubt ist. Ihre Zustimmung ist in den meisten Fällen der wichtigste Grund, dass wir
          Daten in Drittländern verarbeiten lassen. Die Verarbeitung personenbezogener Daten in Drittländern wie den
          USA, wo viele Softwarehersteller Dienstleistungen anbieten und Ihre Serverstandorte haben, kann bedeuten, dass
          personenbezogene Daten auf unerwartete Weise verarbeitet und gespeichert werden.</p>
        <p>Wir weisen ausdrücklich darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes
          Schutzniveau für den Datentransfer in die USA besteht. Die Datenverarbeitung durch US-Dienste (wie
          beispielsweise Google Analytics) kann dazu führen, dass gegebenenfalls Daten nicht anonymisiert verarbeitet
          und gespeichert werden. Ferner können gegebenenfalls US-amerikanische staatliche Behörden Zugriff auf einzelne
          Daten nehmen. Zudem kann es vorkommen, dass erhobene Daten mit Daten aus anderen Diensten desselben Anbieters,
          sofern Sie ein entsprechendes Nutzerkonto haben, verknüpft werden. Nach Möglichkeit versuchen wir
          Serverstandorte innerhalb der EU zu nutzen, sofern das angeboten wird.</p>
        <p>Wir informieren Sie an den passenden Stellen dieser Datenschutzerklärung genauer über Datenübertragung in
          Drittländer, sofern diese zutrifft.</p>
        <h2 className="text-xl mt-4"   id="sicherheit-datenverarbeitung" >Sicherheit der Datenverarbeitung</h2>
        <p>Um personenbezogene Daten zu schützen, haben wir sowohl technische als auch organisatorische Maßnahmen
          umgesetzt. Wo es uns möglich ist, verschlüsseln oder pseudonymisieren wir personenbezogene Daten. Dadurch
          machen wir es im Rahmen unserer Möglichkeiten so schwer wie möglich, dass Dritte aus unseren Daten auf
          persönliche Informationen schließen können.</p>
        <p>Art. 25 DSGVO spricht hier von &#8220;Datenschutz durch Technikgestaltung und durch datenschutzfreundliche
          Voreinstellungen&#8221; und meint damit, dass man sowohl bei Software (z. B. Formularen) also auch Hardware
          (z. B. Zugang zum Serverraum) immer an Sicherheit denkt und entsprechende Maßnahmen setzt. Im Folgenden gehen
          wir, falls erforderlich, noch auf konkrete Maßnahmen ein.</p>
        <h2 className="text-xl mt-4"   id="tls-verschluesselung-https" >TLS-Verschlüsselung mit https</h2>
        <p>TLS, Verschlüsselung und https klingen sehr technisch und sind es auch. Wir verwenden HTTPS (das Hypertext
          Transfer Protocol Secure steht für „sicheres Hypertext-Übertragungsprotokoll“), um Daten abhörsicher im
          Internet zu übertragen.<br/>
          Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem Browser zu unserem Webserver abgesichert
          ist &#8211; niemand kann &#8220;mithören&#8221;.</p>
        <p>Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und erfüllen Datenschutz durch
          Technikgestaltung <a className="adsimple-121890947"
                               href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=121890947"
                               target="_blank" rel="noopener">Artikel 25 Absatz 1 DSGVO</a>). Durch den Einsatz von TLS
          (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet, können
          wir den Schutz vertraulicher Daten sicherstellen.<br/>
          Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol <img role="img"
                                                                                                           src="https://www.adsimple.at/wp-content/uploads/2018/03/schlosssymbol-https.svg"
                                                                                                           width="17"
                                                                                                           height="18"/> links
          oben im Browser, links von der Internetadresse (z. B. beispielseite.de) und der Verwendung des Schemas https
          (anstatt http) als Teil unserer Internetadresse.<br/>
          Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir die Google Suche nach &#8220;Hypertext
          Transfer Protocol Secure wiki&#8221; um gute Links zu weiterführenden Informationen zu erhalten.</p>
        <h2 className="text-xl mt-4"   id="kommunikation" >Kommunikation</h2>
        <table cellPadding="15">
          <tbody>
          <tr>
            <td>
              <strong >Kommunikation Zusammenfassung</strong>
              <br/>
              &#x1f465; Betroffene: Alle, die mit uns per Telefon, E-Mail oder Online-Formular kommunizieren<br/>
              &#x1f4d3; Verarbeitete Daten: z. B. Telefonnummer, Name, E-Mail-Adresse, eingegebene Formulardaten. Mehr
              Details dazu finden Sie bei der jeweils eingesetzten Kontaktart<br/>
              &#x1f91d; Zweck: Abwicklung der Kommunikation mit Kunden, Geschäftspartnern usw.<br/>
              &#x1f4c5; Speicherdauer: Dauer des Geschäftsfalls und der gesetzlichen Vorschriften<br/>
              &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. b DSGVO
              (Vertrag), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
            </td>
          </tr>
          </tbody>
        </table>
        <p>Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular kommunizieren, kann es zur
          Verarbeitung personenbezogener Daten kommen.</p>
        <p>Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des damit zusammenhängenden
          Geschäftsvorgangs verarbeitet. Die Daten während ebensolange gespeichert bzw. so lange es das Gesetz
          vorschreibt.</p>
        <h3 >Betroffene Personen</h3>
        <p>Von den genannten Vorgängen sind alle betroffen, die über die von uns bereit gestellten Kommunikationswege
          den Kontakt zu uns suchen.</p>
        <h3 >Telefon</h3>
        <p>Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät und beim eingesetzten
          Telekommunikationsanbieter pseudonymisiert gespeichert. Außerdem können Daten wie Name und Telefonnummer im
          Anschluss per E-Mail versendet und zur Anfragebeantwortung gespeichert werden. Die Daten werden gelöscht,
          sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
        <h3 >E-Mail</h3>
        <p>Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf dem jeweiligen Endgerät (Computer,
          Laptop, Smartphone,&#8230;) gespeichert und es kommt zur Speicherung von Daten auf dem E-Mail-Server. Die
          Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
        <h3 >Online Formulare</h3>
        <p>Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf unserem Webserver gespeichert und
          gegebenenfalls an eine E-Mail-Adresse von uns weitergeleitet. Die Daten werden gelöscht, sobald der
          Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
        <h3 >Rechtsgrundlagen</h3>
        <p>Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:</p>
        <ul >
          <li >Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die Einwilligung
            Ihre Daten zu speichern und weiter für den Geschäftsfall betreffende Zwecke zu verwenden;
          </li>
          <li >Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für die
            Erfüllung eines Vertrags mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem Telefonanbieter oder wir
            müssen die Daten für vorvertragliche Tätigkeiten, wie z. B. die Vorbereitung eines Angebots, verarbeiten;
          </li>
          <li >Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen
            Kundenanfragen und geschäftliche Kommunikation in einem professionellen Rahmen betreiben. Dazu sind gewisse
            technische Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und Mobilfunkbetreiber notwendig, um
            die Kommunikation effizient betreiben zu können.
          </li>
        </ul>
        <h2 className="text-xl mt-4"   id="cookies" >Cookies</h2>
        <table cellPadding="15">
          <tbody>
          <tr>
            <td>
              <strong >Cookies Zusammenfassung</strong>
              <br/>
              &#x1f465; Betroffene: Besucher der Website<br/>
              &#x1f91d; Zweck: abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim
              Hersteller der Software, der das Cookie setzt.<br/>
              &#x1f4d3; Verarbeitete Daten: Abhängig vom jeweils eingesetzten Cookie. Mehr Details dazu finden Sie
              weiter unten bzw. beim Hersteller der Software, der das Cookie setzt.<br/>
              &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Cookie, kann von Stunden bis hin zu Jahren variieren<br/>
              &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO
              (Berechtigte Interessen)
            </td>
          </tr>
          </tbody>
        </table>
        <h3 >Was sind Cookies?</h3>
        <p>Unsere Website verwendet HTTP-Cookies, um nutzerspezifische Daten zu speichern.<br/>
          Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende
          Datenschutzerklärung besser verstehen.</p>
        <p>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise
          Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Websites speichern kleine
          Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.</p>
        <p>Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Websites verwenden
          Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere Anwendungsbereiche
          gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem Computer gespeichert werden. Diese
          Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem &#8220;Hirn&#8221; Ihres Browsers,
          untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies müssen
          zusätzlich ein oder mehrere Attribute angegeben werden.</p>
        <p>Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche
          Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“
          Informationen an unsere Seite zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet Ihnen die
          Einstellung, die Sie gewohnt sind. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie
          beispielsweise Firefox sind alle Cookies in einer einzigen Datei gespeichert.</p>
        <p>Die folgende Grafik zeigt eine mögliche Interaktion zwischen einem Webbrowser wie z. B. Chrome und dem
          Webserver. Dabei fordert der Webbrowser eine Website an und erhält vom Server ein Cookie zurück, welches der
          Browser erneut verwendet, sobald eine andere Seite angefordert wird.</p>
        <p>
          <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/http-cookie-interaction.svg"
               alt="HTTP Cookie Interaktion zwischen Browser und Webserver" width="100%"/>
        </p>
        <p>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von
          unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Websites (z.B. Google Analytics) erstellt.
          Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines
          Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und
          enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres
          PCs zugreifen.</p>
        <p>So können zum Beispiel Cookie-Daten aussehen:</p>
        <p>
          <strong >Name:</strong> _ga<br/>
          <strong >Wert:</strong> GA1.2.1326744211.152121890947-9<br/>
          <strong >Verwendungszweck:</strong> Unterscheidung der Websitebesucher<br/>
          <strong >Ablaufdatum:</strong> nach 2 Jahren</p>
        <p>Diese Mindestgrößen sollte ein Browser unterstützen können:</p>
        <ul >
          <li >Mindestens 4096 Bytes pro Cookie</li>
          <li >Mindestens 50 Cookies pro Domain</li>
          <li >Mindestens 3000 Cookies insgesamt</li>
        </ul>
        <h3 >Welche Arten von Cookies gibt es?</h3>
        <p>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in den
          folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die
          verschiedenen Arten von HTTP-Cookies eingehen.</p>
        <p>Man kann 4 Arten von Cookies unterscheiden:</p>
        <p>
          <strong >Unerlässliche Cookies<br/>
          </strong>Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel
          braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft
          und später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der User
          sein Browserfenster schließt.</p>
        <p>
          <strong >Zweckmäßige Cookies<br/>
          </strong>Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt.
          Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen
          Browsern gemessen.</p>
        <p>
          <strong >Zielorientierte Cookies<br/>
          </strong>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene
          Standorte, Schriftgrößen oder Formulardaten gespeichert.</p>
        <p>
          <strong >Werbe-Cookies<br/>
          </strong>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste
          Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.</p>
        <p>Üblicherweise werden Sie beim erstmaligen Besuch einer Website gefragt, welche dieser Cookiearten Sie
          zulassen möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.</p>
        <p>Wenn Sie mehr über Cookies wissen möchten und technische Dokumentationen nicht scheuen, empfehlen wir <a
          className="adsimple-121890947" href="https://tools.ietf.org/html/rfc6265" target="_blank"
          rel="nofollow noopener">https://tools.ietf.org/html/rfc6265</a>, dem Request for Comments der Internet
          Engineering Task Force (IETF) namens &#8220;HTTP State Management Mechanism&#8221;.</p>
        <h3 >Zweck der Verarbeitung über Cookies</h3>
        <p>Der Zweck ist letztendlich abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw.
          beim Hersteller der Software, die das Cookie setzt.</p>
        <h3 >Welche Daten werden verarbeitet?</h3>
        <p>Cookies sind kleine Gehilfen für eine viele verschiedene Aufgaben. Welche Daten in Cookies gespeichert
          werden, kann man leider nicht verallgemeinern, aber wir werden Sie im Rahmen der folgenden
          Datenschutzerklärung über die verarbeiteten bzw. gespeicherten Daten informieren.</p>
        <h3 >Speicherdauer von Cookies</h3>
        <p>Die Speicherdauer hängt vom jeweiligen Cookie ab und wird weiter unter präzisiert. Manche Cookies werden nach
          weniger als einer Stunde gelöscht, andere können mehrere Jahre auf einem Computer gespeichert bleiben.</p>
        <p>Sie haben außerdem selbst Einfluss auf die Speicherdauer. Sie können über ihren Browser sämtliche Cookies
          jederzeit manuell löschen (siehe auch unten &#8220;Widerspruchsrecht&#8221;). Ferner werden Cookies, die auf
          einer Einwilligung beruhen, spätestens nach Widerruf Ihrer Einwilligung gelöscht, wobei die Rechtmäßigkeit der
          Speicherung bis dahin unberührt bleibt.</p>
        <h3 >Widerspruchsrecht &#8211; wie kann ich Cookies löschen?</h3>
        <p>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher
          Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, zu deaktivieren oder nur
          teilweise zuzulassen. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies
          zulassen.</p>
        <p>Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie
          Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:</p>
        <p>
          <a className="adsimple-121890947" href="https://support.google.com/chrome/answer/95647?tid=121890947"
             target="_blank" rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
        </p>
        <p>
          <a className="adsimple-121890947"
             href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=121890947" target="_blank"
             rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
        </p>
        <p>
          <a className="adsimple-121890947"
             href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=121890947"
             target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben</a>
        </p>
        <p>
          <a className="adsimple-121890947"
             href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=121890947"
             target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a>
        </p>
        <p>
          <a className="adsimple-121890947"
             href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=121890947"
             target="_blank" rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a>
        </p>
        <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer
          informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie
          das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten Sie suchen die
          Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder &#8220;Cookies deaktivieren
          Chrome&#8221; im Falle eines Chrome Browsers.</p>
        <h3 >Rechtsgrundlage</h3>
        <p>Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von
          Cookies eine <strong >Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO) von
          Ihnen verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese
          Richtlinien. In Österreich erfolgte aber die Umsetzung dieser Richtlinie in § 96 Abs. 3 des
          Telekommunikationsgesetzes (TKG). In Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht
          umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des
          Telemediengesetzes (TMG).</p>
        <p>Für unbedingt notwendige Cookies, auch soweit keine Einwilligung vorliegt. bestehen <strong
          >berechtigte Interessen</strong> (Artikel 6 Abs. 1 lit. f DSGVO), die in den
          meisten Fällen wirtschaftlicher Natur sind. Wir möchten den Besuchern der Website eine angenehme
          Benutzererfahrung bescheren und dafür sind bestimmte Cookies oft unbedingt notwendig.</p>
        <p>Soweit nicht unbedingt erforderliche Cookies zum Einsatz kommen, geschieht dies nur im Falle Ihrer
          Einwilligung. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO.</p>
        <p>In den folgenden Abschnitten werden Sie genauer über den Einsatz von Cookies informiert, sofern eingesetzte
          Software Cookies verwendet.</p>
        <h2 className="text-xl mt-4"   id="webhosting" >Webhosting</h2>
        <table cellPadding="15">
          <tbody>
          <tr>
            <td>
              <strong >Webhosting Zusammenfassung</strong>
              <br/>
              &#x1f465; Betroffene: Besucher der Website<br/>
              &#x1f91d; Zweck: professionelles Hosting der Website und Absicherung des Betriebs<br/>
              &#x1f4d3; Verarbeitete Daten: IP-Adresse, Zeitpunkt des Websitebesuchs, verwendeter Browser und weitere
              Daten. Mehr Details dazu finden Sie weiter unten bzw. beim jeweils eingesetzten Webhosting Provider.<br/>
              &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Provider, aber in der Regel 2 Wochen<br/>
              &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)
            </td>
          </tr>
          </tbody>
        </table>
        <h3 >Was ist Webhosting?</h3>
        <p>Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen &#8211; auch personenbezogene
          Daten &#8211; automatisch erstellt und gespeichert, so auch auf dieser Website. Diese Daten sollten möglichst
          sparsam und nur mit Begründung verarbeitet werden. Mit Website meinen wir übrigens die Gesamtheit aller
          Webseiten auf einer Domain, d.h. alles von der Startseite (Homepage) bis hin zur aller letzten Unterseite (wie
          dieser hier). Mit Domain meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.</p>
        <p>Wenn Sie eine Website auf einem Bildschirm ansehen möchten, verwenden Sie dafür ein Programm, das sich
          Webbrowser nennt. Sie kennen vermutlich einige Webbrowser beim Namen: Google Chrome, Microsoft Edge, Mozilla
          Firefox und Apple Safari.</p>
        <p>Dieser Webbrowser muss sich zu einem anderen Computer verbinden, wo der Code der Website gespeichert ist: dem
          Webserver. Der Betrieb eines Webservers ist eine komplizierte und aufwendige Aufgabe, weswegen dies in der
          Regel von professionellen Anbietern, den Providern, übernommen wird. Diese bieten Webhosting an und sorgen
          damit für eine verlässliche und fehlerfreie Speicherung der Daten von Websites.</p>
        <p>Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop, Laptop, Smartphone) und während der
          Datenübertragung zu und vom Webserver kann es zu einer Verarbeitung personenbezogener Daten kommen. Einerseits
          speichert Ihr Computer Daten, andererseits muss auch der Webserver Daten eine Zeit lang speichern, um einen
          ordentlichen Betrieb zu gewährleisten.</p>
        <p>Zur Veranschaulichung:</p>
        <p>
          <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg"
               alt="Browser und Webserver" width="100%"/>
        </p>
        <h3 >Warum verarbeiten wir personenbezogene Daten?</h3>
        <p>Die Zwecke der Datenverarbeitung sind:</p>
        <ol>
          <li >Professionelles Hosting der Website und Absicherung des Betriebs</li>
          <li >zur Aufrechterhaltung der Betriebs- und IT-Sicherheit</li>
          <li >Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres Angebots
            und ggf. zur Strafverfolgung bzw. Verfolgung von Ansprüchen
          </li>
        </ol>
        <h3 >Welche Daten werden verarbeitet?</h3>
        <p>Auch während Sie unsere Website jetzt gerade besuchen, speichert unser Webserver, das ist der Computer auf
          dem diese Webseite gespeichert ist, in der Regel automatisch Daten wie</p>
        <ul >
          <li >die komplette Internetadresse (URL) der aufgerufenen Webseite (z. B.
            https://www.beispielwebsite.de/beispielunterseite.html?tid=121890947)
          </li>
          <li >Browser und Browserversion (z. B. Chrome 87)</li>
          <li >das verwendete Betriebssystem (z. B. Windows 10)</li>
          <li >die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B.
            https://www.beispielquellsite.de/vondabinichgekommen.html/)
          </li>
          <li >den Hostnamen und die IP-Adresse des Geräts von welchem aus zugegriffen
            wird (z. B. COMPUTERNAME und 194.23.43.121)
          </li>
          <li >Datum und Uhrzeit</li>
          <li >in Dateien, den sogenannten Webserver-Logfiles</li>
        </ul>
        <h3 >Wie lange werden Daten gespeichert?</h3>
        <p>In der Regel werden die oben genannten Daten zwei Wochen gespeichert und danach automatisch gelöscht. Wir
          geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von
          rechtswidrigem Verhalten von Behörden eingesehen werden.</p>
        <p>
          <strong >Kurz gesagt:</strong> Ihr Besuch wird durch unseren Provider (Firma,
          die unsere Website auf speziellen Computern (Servern) laufen lässt), protokolliert, aber wir geben Ihre Daten
          nicht ohne Zustimmung weiter!</p>
        <h3 >Rechtsgrundlage</h3>
        <p>Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im Rahmen des Webhosting ergibt sich aus Art. 6
          Abs. 1 lit. f DSGVO (Wahrung der berechtigten Interessen), denn die Nutzung von professionellem Hosting bei
          einem Provider ist notwendig, um das Unternehmen im Internet sicher und nutzerfreundlich präsentieren und
          Angriffe und Forderungen hieraus gegebenenfalls verfolgen zu können.</p>
        <p>Zwischen uns und dem Hostingprovider besteht in der Regel ein Vertrag über die Auftragsverarbeitung gemäß
          Art. 28 f. DSGVO, der die Einhaltung von Datenschutz gewährleistet und Datensicherheit garantiert.</p>
        <h2 className="text-xl mt-4"   id="content-delivery-networks" >Content Delivery Networks</h2>
        <table cellPadding="15">
          <tbody>
          <tr>
            <td>
              <strong >Content Delivery Networks Datenschutzerklärung
                Zusammenfassung</strong>
              <br/>
              &#x1f465; Betroffene: Besucher der Website<br/>
              &#x1f91d; Zweck: Optimierung unserer Serviceleistung (um die Website schneller laden zu können)<br/>
              &#x1f4d3; Verarbeitete Daten: Daten wie etwa Ihre IP-Adresse<br/>
              Mehr Details dazu finden Sie weiter unten und den einzelnen Datenschutztexten.<br/>
              &#x1f4c5; Speicherdauer: meisten werden die Daten solange gespeichert, bis sie zur Erfüllung der
              Dienstleistung nicht mehr benötigt werden<br/>
              &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO
              (Berechtigte Interessen)
            </td>
          </tr>
          </tbody>
        </table>
        <h3 >Was ist ein Content Delivery Network?</h3>
        <p>Wir nutzen auf unserer Website ein sogenanntes Content Delivery Network. Meistens wird ein solchen Netzwerk
          nur CDN genannt. Ein CDN hilft uns, unsere Website, unabhängig von Ihrem Standort, schnell und problemlos zu
          laden. Dabei werden auch personenbezogene Daten von Ihnen auf den Servern des eingesetzten CDN-Anbieters
          gespeichert, verwaltet und verarbeitet. Nachfolgend gehen wir allgemein näher auf den Dienst und dessen
          Datenverarbeitung ein. Genaue Informationen über den Umgang mit Ihren Daten finden Sie in der jeweiligen
          Datenschutzerklärung des Anbieters.</p>
        <p>Jedes Content Delivery Network (CDN) ist ein Netzwerk regional verteilter Server, die alle über das Internet
          miteinander verbunden sind. Über dieses Netzwerk können Inhalte von Websites (speziell auch sehr große
          Dateien) auch bei großen Lastspitzen schnell und reibungslos ausgeliefert werden. Das CDN legt dafür eine
          Kopie unserer Website auf Ihren Servern an. Da diese Server weltweit verteilt sind, kann die Website schnell
          ausgeliefert werden. Die Datenübertragung zu Ihrem Browser wird folglich durch das CDN deutlich verkürzt.</p>
        <h3 >Warum verwenden wir ein Content Delivery Network für unsere Website?</h3>
        <p>Eine schnell ladende Website ist Teil unserer Dienstleistung. Wir wissen natürlich, wie nervig es ist, wenn
          eine Website im Schneckentempo lädt. Meist verliert man sogar die Geduld und sucht das Weite, bevor die
          Website vollständig geladen ist. Das wollen wir natürlich vermeiden. Daher gehört eine schnell ladende Website
          ganz selbstverständlich zu unserem Websiteangebot. Mit einem Content Delivery Network wird in Ihrem Browser
          unsere Website deutlich schneller geladen. Besonders hilfreich ist der Einsatz des CDNs wenn Sie im Ausland
          sind, weil die Website von einem Server in Ihrer Nähe ausgeliefert wird.</p>
        <h3 >Welche Daten werden verarbeitet?</h3>
        <p>Wenn Sie eine Website bzw. die Inhalte einer Website anfordern und diese in einem CDN zwischengespeichert
          sind, leitet das CDN die Anforderung an den von Ihnen am nächsten gelegenen Server und dieser liefert die
          Inhalte aus. Content Delivery Networks sind so aufgebaut, dass JavaScript-Bibliotheken heruntergeladen werden
          können und auf npm- und Github-Servern gehostet werden. Alternativ können bei den meisten CDNs auch
          WordPress-Plugins geladen werden, wenn diese auf <a className="adsimple-121890947"
                                                              href="https://wordpress.org/" target="_blank"
                                                              rel="noopener">WordPress.org</a> gehostet werden. Ihr
          Browser kann personenbezogene Daten an das von uns eingesetzte Content Delivery Network senden. Dabei handelt
          es sich etwa um Daten wie IP-Adresse, Browsertyp, Browserversion, welche Webseite geladen wird oder Uhrzeit
          und Datum des Seitenbesuchs. Diese Daten werden vom CDN gesammelt und auch gespeichert. Ob zur
          Datenspeicherung Cookies verwendet werden, hängt von dem eingesetzten Network ab. Bitte lesen Sie sich dafür
          die Datenschutztexte des jeweiligen Dienstes durch.</p>
        <h3 >Widerspruchsrecht</h3>
        <p>Falls Sie diese Datenübertragung vollkommen unterbinden wollen, können Sie einen JavaScript-Blocker (siehe
          beispielsweise <a className="adsimple-121890947" href="https://noscript.net/" target="_blank"
                            rel="noopener">https://noscript.net/</a>) auf Ihrem PC installieren. Natürlich kann dann
          unsere Website nicht mehr das gewohnte Service (wie beispielsweise eine schnelle Ladegeschwindigkeit) bieten.
        </p>
        <h3 >Rechtsgrundlage</h3>
        <p>Wenn Sie eingewilligt haben, dass ein Content Delivery Network eingesetzt werden darf, ist die
          Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt
          laut <strong >Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die
          Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch ein Content
          Delivery Network vorkommen kann, dar.</p>
        <p>Von unserer Seite besteht zudem ein berechtigtes Interesse, ein Content Delivery Network zu verwenden, um
          unser Online-Service zu optimieren und sicherer zu machen. Die dafür entsprechende Rechtsgrundlage ist <strong
            >Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen das
          Tool gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
        <p>Informationen zu speziellen Content Delivery Network erhalten Sie &#8211; sofern vorhanden &#8211; in den
          folgenden Abschnitten.</p>
        <h2 className="text-xl mt-4"   id="benutzerdefinierte-google-suche-datenschutzerklaerung" >Benutzerdefinierte
          Google Suche Datenschutzerklärung</h2>
        <table cellPadding="15">
          <tbody>
          <tr>
            <td>
              <strong >Benutzerdefinierte Google Suche Datenschutzerklärung
                Zusammenfassung</strong>
              <br/>
              &#x1f465; Betroffene: Besucher der Website<br/>
              &#x1f91d; Zweck: Optimierung unserer Serviceleistung<br/>
              &#x1f4d3; Verarbeitete Daten: Daten wie etwa IP-Adresse und eingegebene Suchbegriffe werden bei Google
              gespeichert<br/>
              Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br/>
              &#x1f4c5; Speicherdauer: die Speicherdauer variiert abhängig von den gespeicherten Daten<br/>
              &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO
              (Berechtigte Interessen)
            </td>
          </tr>
          </tbody>
        </table>
        <h3 >Was ist die benutzerdefinierte Google Suche?</h3>
        <p>Wir haben auf unserer Website das Google-Plug-in zur benutzerdefinierten Suche eingebunden. Google ist die
          größte und bekannteste Suchmaschine weltweit und wird von dem US-amerikanische Unternehmen Google Inc.
          betrieben. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street
          Dublin 4, Irland) verantwortlich. Durch die benutzerdefinierte Google Suche können Daten von Ihnen an Google
          übertragen werden. In dieser Datenschutzerklärung informieren wir Sie, warum wir dieses Plug-in verwenden,
          welche Daten verarbeitet werden und wie Sie diese Datenübertragung verwalten oder unterbinden können.</p>
        <p>Das Plug-in zur benutzerdefinierten Google Suche ist eine Google-Suchleiste direkt auf unserer Website. Die
          Suche findet wie auf <a className="adsimple-121890947" href="https://www.google.com?tid=121890947"
                                  target="_blank" rel="follow noopener noreferrer">www.google.com</a> statt, nur
          fokussieren sich die Suchergebnisse auf unsere Inhalte und Produkte bzw. auf einen eingeschränkten Suchkreis.
        </p>
        <h3 >Warum verwenden wir die benutzerdefinierte Google Suche auf unserer
          Website?</h3>
        <p>Eine Website mit vielen interessanten Inhalten wird oft so groß, dass man unter Umständen den Überblick
          verliert. Über die Zeit hat sich auch bei uns viel wertvolles Material angesammelt und wir wollen als Teil
          unserer Dienstleistung, dass Sie unsere Inhalte so schnell und einfach wie möglich finden. Durch die
          benutzerdefinierte Google-Suche wird das Finden von interessanten Inhalten zu einem Kinderspiel. Das
          eingebaute Google-Plug-in verbessert insgesamt die Qualität unserer Website und macht Ihnen das Suchen
          leichter.</p>
        <h3 >Welche Daten werden durch die benutzerdefinierte Google Suche
          gespeichert?</h3>
        <p>Durch die benutzerdefinierte Google-Suche werden nur Daten von Ihnen an Google übertragen, wenn Sie die auf
          unserer Website eingebaute Google-Suche aktiv verwenden. Das heißt, erst wenn Sie einen Suchbegriff in die
          Suchleiste eingeben und dann diesen Begriff bestätigen (z.B. auf „Enter“ klicken) wird neben dem Suchbegriff
          auch Ihre IP-Adresse an Google gesandt, gespeichert und dort verarbeitet. Anhand der gesetzten Cookies (wie
          z.B. 1P_JAR) ist davon auszugehen, dass Google auch Daten zur Webseiten-Nutzung erhält. Wenn Sie während Ihrem
          Besuch auf unserer Webseite, über die eingebaute Google-Suchfunktion, Inhalte suchen und gleichzeitig mit
          Ihrem Google-Konto angemeldet sind, kann Google die erhobenen Daten auch Ihrem Google-Konto zuordnen. Als
          Websitebetreiber haben wir keinen Einfluss darauf, was Google mit den erhobenen Daten macht bzw. wie Google
          die Daten verarbeitet.</p>
        <p>Folgende Cookie werden in Ihrem Browser gesetzt, wenn Sie die benutzerdefinierte Google Suche verwenden und
          nicht mit einem Google-Konto angemeldet sind:</p>
        <p>
          <strong >Name:</strong> 1P_JAR<br/>
          <strong >Wert:</strong> 2020-01-27-13121890947-5<br/>
          <strong >Verwendungszweck:</strong> Dieses Cookie sammelt Statistiken zur
          Website-Nutzung und misst Conversions. Eine Conversion entsteht zum Beispiel, wenn ein User zu einem Käufer
          wird. Das Cookie wird auch verwendet, um Usern relevante Werbeanzeigen einzublenden.<br/>
          <strong >Ablaufdatum:</strong> nach einem Monat</p>
        <p>
          <strong >Name:</strong> CONSENT<br/>
          <strong >Wert:</strong> WP.282f52121890947-9<br/>
          <strong >Verwendungszweck:</strong> Das Cookie speichert den Status der
          Zustimmung eines Users zur Nutzung unterschiedlicher Services von Google. CONSENT dient auch der Sicherheit,
          um User zu überprüfen und Userdaten vor unbefugten Angriffen zu schützen.<br/>
          <strong >Ablaufdatum:</strong> nach 18 Jahren</p>
        <p>
          <strong >Name:</strong> NID<br/>
          <strong >Wert:</strong> 196=pwIo3B5fHr-8<br/>
          <strong >Verwendungszweck:</strong> NID wird von Google verwendet, um
          Werbeanzeigen an Ihre Google-Suche anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google an Ihre
          eingegebenen Suchanfragen oder Ihre frühere Interaktion mit Anzeigen. So bekommen Sie immer maßgeschneiderte
          Werbeanzeigen.<br/>
          <strong >Ablaufdatum:</strong> nach 6 Monaten</p>
        <p>
          <strong >Anmerkung:</strong> Diese Aufzählung kann keinen Anspruch auf
          Vollständigkeit erheben, da Google die Wahl ihrer Cookies immer wieder auch verändert.</p>
        <h3 >Wie lange und wo werden die Daten gespeichert?</h3>
        <p>Die Google-Server sind auf der ganzen Welt verteilt. Da es sich bei Google um ein amerikanisches Unternehmen
          handelt, werden die meisten Daten auf amerikanischen Servern gespeichert. Unter <a
            className="adsimple-121890947" href="https://www.google.com/about/datacenters/inside/locations/?hl=de"
            target="_blank"
            rel="follow noopener noreferrer">https://www.google.com/about/datacenters/inside/locations/?hl=de</a> sehen
          Sie genau, wo die Google-Server stehen.<br/>
          Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt. Dadurch sind die Daten schneller
          abrufbar und vor möglichen Manipulationen besser geschützt. Google hat auch entsprechende Notfallprogramme für
          Ihre Daten. Wenn es beispielsweise bei Google interne technische Probleme gibt und dadurch Server nicht mehr
          funktionieren, bleibt das Risiko einer Dienstunterbrechung und eines Datenverlusts dennoch gering.<br/>
          Je nach dem um welche Daten es sich handelt, speichert Google diese unterschiedlich lange. Manche Daten können
          Sie selbst löschen, andere werden von Google automatisch gelöscht oder anonymisiert. Es gibt aber auch Daten,
          die Google länger speichert, wenn dies aus juristischen oder geschäftlichen Gründen erforderlich ist.</p>
        <h3 >Wie kann ich meinen Daten löschen bzw. die Datenspeicherung verhindern?</h3>
        <p>Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht, Auskunft über Ihre Daten zu erhalten,
          sie zu aktualisieren, zu löschen oder einzuschränken. Es gibt einige Daten, die Sie jederzeit löschen können.
          Wenn Sie ein Google-Konto besitzen, können Sie dort Daten zu Ihrer Webaktivität löschen bzw. festlegen, dass
          sie nach einer bestimmten Zeit gelöscht werden sollen.<br/>
          In Ihrem Browser haben Sie zudem die Möglichkeit, Cookies zu deaktivieren, zu löschen oder nach Ihren Wünschen
          und Vorlieben zu verwalten. Hier finden Sie Anleitungen zu den wichtigsten Browsern:</p>
        <p>
          <a className="adsimple-121890947" href="https://support.google.com/chrome/answer/95647?tid=121890947"
             target="_blank" rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
        </p>
        <p>
          <a className="adsimple-121890947"
             href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=121890947" target="_blank"
             rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
        </p>
        <p>
          <a className="adsimple-121890947"
             href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=121890947"
             target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben</a>
        </p>
        <p>
          <a className="adsimple-121890947"
             href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=121890947"
             target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a>
        </p>
        <p>
          <a className="adsimple-121890947"
             href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=121890947"
             target="_blank" rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a>
        </p>
        <h3 >Rechtsgrundlage</h3>
        <p>Wenn Sie eingewilligt haben, dass die benutzerdefinierte Google Suche eingesetzt werden darf, ist die
          Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong
            > Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für
          die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch die benutzerdefinierte Google Suche
          vorkommen kann, dar.</p>
        <p>Von unserer Seite besteht zudem ein berechtigtes Interesse, die benutzerdefinierte Google Suche zu verwenden,
          um unser Online-Service zu optimieren. Die dafür entsprechende Rechtsgrundlage ist <strong
            >Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen die
          benutzerdefinierte Google Suche gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
        <p>Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des
          Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht.
          Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung
          einhergehen.</p>
        <p>Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen
          Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin
          verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln
          (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen
          sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in
          Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln
          verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau
          einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln
          basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden
          Standardvertragsklauseln u.a. hier: <a className="adsimple-121890947"
                                                 href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de"
                                                 target="_blank"
                                                 rel="noopener">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
        </p>
        <p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche den
          Standardvertragsklauseln entsprechen, finden Sie unter <a className="adsimple-121890947"
                                                                    href="https://business.safety.google/adsprocessorterms/"
                                                                    target="_blank"
                                                                    rel="noopener">https://business.safety.google/adsprocessorterms/</a>.
        </p>
        <p>Wir hoffen wir konnten Ihnen die wichtigsten Informationen rund um die Datenverarbeitung durch Google
          näherbringen. Wenn Sie mehr darüber erfahren wollen, empfehlen wir die umfangreiche Datenschutzerklärung von
          Google unter <a className="adsimple-121890947" href="https://policies.google.com/privacy?hl=de"
                          target="_blank" rel="follow noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.
        </p>
        <h2 className="text-xl mt-4"   id="font-awesome-datenschutzerklaerung" >Font Awesome
          Datenschutzerklärung</h2>
        <table cellPadding="15">
          <tbody>
          <tr>
            <td>
              <strong >Font Awesome Datenschutzerklärung Zusammenfassung</strong>
              <br/>
              &#x1f465; Betroffene: Besucher der Website<br/>
              &#x1f91d; Zweck: Optimierung unserer Serviceleistung<br/>
              &#x1f4d3; Verarbeitete Daten: etwa IP-Adresse und und welche Icon-Dateien geladen werden<br/>
              Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br/>
              &#x1f4c5; Speicherdauer: Dateien in identifizierbarer Form werden wenige Wochen gespeichert<br/>
              &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO
              (Berechtigte Interessen)
            </td>
          </tr>
          </tbody>
        </table>
        <h3 >Was ist Font Awesome?</h3>
        <p>Wir verwenden auf unserer Website Font Awesome des amerikanischen Unternehmens Fonticons (307 S. Main St.,
          Suite 202, Bentonville, AR 72712, USA). Wenn Sie eine unserer Webseite aufrufen, wird die Web-Schriftart Font
          Awesome (im Speziellen Icons) über das Font Awesome Content Delivery Netzwerk (CDN) geladen. So werden die
          Texte bzw. Schriften und Icons auf jedem Endgerät passend angezeigt. In dieser Datenschutzerklärung gehen wir
          näher auf die Datenspeicherung und Datenverarbeitung durch diesen Service ein.</p>
        <p>Icons spielen für Websites eine immer wichtigere Rolle. Font Awesome ist eine Web-Schriftart, die speziell
          für Webdesigner und Webentwickler entwickelt wurde. Mit Font Awesome können etwa Icons mit Hilfe der
          Stylesheet-Sprache CSS nach Belieben skaliert und gefärbt werden. Sie ersetzen so alte Bild-Icons. Font
          Awesome CDN ist der einfachste Weg die Icons oder Schriftarten auf Ihre Website zu laden. Dafür mussten wir
          nur eine kleine Code-Zeile in unsere Website einbinden.</p>
        <h3 >Warum verwenden wir Font Awesome auf unserer Website?</h3>
        <p>Durch Font Awesome können Inhalte auf unserer Website besser aufbereitet werden. So können Sie sich auf
          unserer Website besser orientieren und die Inhalte leichter erfassen. Mit den Icons kann man sogar manchmal
          ganze Wörter ersetzen und Platz sparen. Da ist besonders praktisch, wenn wir Inhalte speziell für Smartphones
          optimieren. Diese Icons werden statt als Bild als HMTL-Code eingefügt. Dadurch können wir die Icons mit CSS
          genauso bearbeiten, wie wir wollen. Gleichzeitig verbessern wir mit Font Awesome auch unsere
          Ladegeschwindigkeit, weil es sich nur um HTML-Elemente handelt und nicht um Icon-Bilder. All diese Vorteile
          helfen uns, die Website für Sie noch übersichtlicher, frischer und schneller zu machen.</p>
        <h3 >Welche Daten werden von Font Awesome gespeichert?</h3>
        <p>Zum Laden von Icons und Symbolen wird das Font Awesome Content Delivery Network (CDN) verwendet. CDNs sind
          Netzwerke von Servern, die weltweit verteilt sind und es möglich machen, schnell Dateien aus der Nähe zu
          laden. So werden auch, sobald Sie eine unserer Seiten aufrufen, die entsprechenden Icons von Font Awesome
          bereitgestellt.</p>
        <p>Damit die Web-Schriftarten geladen werden können, muss Ihr Browser eine Verbindung zu den Servern des
          Unternehmens Fonticons, Inc. herstellen. Dabei wird Ihre IP-Adresse erkannt. Font Awesome sammelt auch Daten
          darüber, welche Icon-Dateien wann heruntergeladen werden. Weiters werden auch technische Daten wie etwa Ihre
          Browser-Version, Bildschirmauflösung oder der Zeitpunkt der ausgerufenen Seite übertragen.</p>
        <p>Aus folgenden Gründen werden diese Daten gesammelt und gespeichert:</p>
        <ul >
          <li >um Content Delivery Netzwerke zu optimieren</li>
          <li >um technische Fehler zu erkennen und zu beheben</li>
          <li >um CDNs vor Missbrauch und Angriffen zu schützen</li>
          <li >um Gebühren von Font Awesome Pro-Kunden berechnen zu können</li>
          <li >um die Beliebtheit von Icons zu erfahren</li>
          <li >um zu wissen, welchen Computer und welche Software Sie verwenden</li>
        </ul>
        <p>Falls Ihr Browser Web-Schriftarten nicht zulässt, wird automatisch eine Standardschrift Ihres PCs verwendet.
          Nach derzeitigem Stand unserer Erkenntnis werden keine Cookies gesetzt. Wir sind mit der Datenschutzabteilung
          von Font Awesome in Kontakt und geben Ihnen Bescheid, sobald wir näheres in Erfahrung bringen.</p>
        <h3 >Wie lange und wo werden die Daten gespeichert?</h3>
        <p>Font Awesome speichert Daten über die Nutzung des Content Delivery Network auf Servern auch in den
          Vereinigten Staaten von Amerika. Die CDN-Server befinden sich allerdings weltweit und speichern Userdaten, wo
          Sie sich befinden. In identifizierbarer Form werden die Daten in der Regel nur wenige Wochen gespeichert.
          Aggregierte Statistiken über die Nutzung von den CDNs können auch länger gespeichert werden. Personenbezogene
          Daten sind hier nicht enthalten.</p>
        <h3 >Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
        <p>Font Awesome speichert nach aktuellem Stand unseres Wissens keine personenbezogenen Daten über die Content
          Delivery Netzwerke. Wenn Sie nicht wollen, dass Daten über die verwendeten Icons gespeichert werden, können
          Sie leider unsere Website nicht besuchen. Wenn Ihr Browser keine Web-Schriftarten erlaubt, werden auch keine
          Daten übertragen oder gespeichert. In diesem Fall wird einfach die Standard-Schrift Ihres Computers
          verwendet.</p>
        <h3 >Rechtsgrundlage</h3>
        <p>Wenn Sie eingewilligt haben, dass Font Awesome eingesetzt werden darf, ist die Rechtsgrundlage der
          entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong
            > Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für
          die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Font Awesome vorkommen kann, dar.
        </p>
        <p>Von unserer Seite besteht zudem ein berechtigtes Interesse, Font Awesome zu verwenden, um unser
          Online-Service zu optimieren. Die dafür entsprechende Rechtsgrundlage ist <strong
            >Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Font
          Awesome gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
        <p>Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau
          für den Datentransfer in die USA besteht. Die Datenverarbeitung geschieht im Wesentlichen durch Font Awesome.
          Dies kann dazu führen, dass gegebenenfalls Daten nicht anonymisiert verarbeitet und gespeichert werden. Ferner
          können gegebenenfalls US-amerikanische staatliche Behörden Zugriff auf einzelne Daten nehmen. Es kann ferner
          vorkommen, dass diese Daten mit Daten aus möglichen anderen Diensten von Font Awesome, bei denen Sie ein
          Nutzerkonto haben, verknüpft werden.</p>
        <p>Wenn Sie mehr über Font Awesome und deren Umgang mit Daten erfahren wollen, empfehlen wir Ihnen die
          Datenschutzerklärung unter <a className="adsimple-121890947"
                                        href="https://fontawesome.com/privacy">https://fontawesome.com/privacy</a> und
          die Hilfeseite unter <a className="adsimple-121890947"
                                  href="https://fontawesome.com/help?tid=121890947">https://fontawesome.com/help</a>.
        </p>
        <h2 className="text-xl mt-4"   id="google-fonts-datenschutzerklaerung" >Google Fonts
          Datenschutzerklärung</h2>
        <table cellPadding="15">
          <tbody>
          <tr>
            <td>
              <strong >Google Fonts Datenschutzerklärung Zusammenfassung</strong>
              <br/>
              &#x1f465; Betroffene: Besucher der Website<br/>
              &#x1f91d; Zweck: Optimierung unserer Serviceleistung<br/>
              &#x1f4d3; Verarbeitete Daten: Daten wie etwa IP-Adresse und CSS- und Schrift-Anfragen<br/>
              Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br/>
              &#x1f4c5; Speicherdauer: Font-Dateien werden bei Google ein Jahr gespeichert<br/>
              &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO
              (Berechtigte Interessen)
            </td>
          </tr>
          </tbody>
        </table>
        <h3 >Was sind Google Fonts?</h3>
        <p>Auf unserer Website verwenden wir Google Fonts. Das sind die &#8220;Google-Schriften&#8221; der Firma Google
          Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin
          4, Irland) für alle Google-Dienste verantwortlich.</p>
        <p>Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen.
          Weiters werden auch keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden
          über die Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die Anfragen
          nach CSS und Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Sie ein Google-Konto haben,
          brauchen Sie keine Sorge haben, dass Ihre Google-Kontodaten, während der Verwendung von Google Fonts, an
          Google übermittelt werden. Google erfasst die Nutzung von CSS (Cascading Style Sheets) und der verwendeten
          Schriftarten und speichert diese Daten sicher. Wie die Datenspeicherung genau aussieht, werden wir uns noch im
          Detail ansehen.</p>
        <p>Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800 Schriftarten, die <a
          >Google</a> Ihren
          Nutzern kostenlos zu Verfügung stellen.</p>
        <p>Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere unter der
          Apache-Lizenz veröffentlicht wurden. Beides sind freie Software-Lizenzen.</p>
        <h3 >Warum verwenden wir Google Fonts auf unserer Website?</h3>
        <p>Mit Google Fonts können wir auf der eigenen Webseite Schriften nutzen, und müssen sie nicht auf unserem
          eigenen Server hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch zu
          halten. Alle Google-Schriften sind automatisch für das Web optimiert und dies spart Datenvolumen und ist
          speziell für die Verwendung bei mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt
          die niedrige Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google Fonts sichere Web Fonts.
          Unterschiedliche Bildsynthese-Systeme (Rendering) in verschiedenen Browsern, Betriebssystemen und mobilen
          Endgeräten können zu Fehlern führen. Solche Fehler können teilweise Texte bzw. ganze Webseiten optisch
          verzerren. Dank des schnellen Content Delivery Network (CDN) gibt es mit Google Fonts keine
          plattformübergreifenden Probleme. Google Fonts unterstützt alle gängigen Browser (Google Chrome, Mozilla
          Firefox, Apple Safari, Opera) und funktioniert zuverlässig auf den meisten modernen mobilen Betriebssystemen,
          einschließlich Android 2.2+ und iOS 4.2+ (iPhone, iPad, iPod). Wir verwenden die Google Fonts also, damit wir
          unser gesamtes Online-Service so schön und einheitlich wie möglich darstellen können.</p>
        <h3 >Welche Daten werden von Google gespeichert?</h3>
        <p>Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen. Durch diesen
          externen Aufruf werden Daten an die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw. Ihre
          IP-Adresse unsere Webseite besucht. Die Google Fonts API wurde entwickelt, um Verwendung, Speicherung und
          Erfassung von Endnutzerdaten auf das zu reduzieren, was für eine ordentliche Bereitstellung von Schriften
          nötig ist. API steht übrigens für „Application Programming Interface“ und dient unter anderem als
          Datenübermittler im Softwarebereich.</p>
        <p>Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und ist somit geschützt. Durch die
          gesammelten Nutzungszahlen kann Google feststellen, wie gut die einzelnen Schriften ankommen. Die Ergebnisse
          veröffentlicht Google auf internen Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet Google
          auch Daten des eigenen Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften verwenden. Diese
          Daten werden in der BigQuery-Datenbank von Google Fonts veröffentlicht. Unternehmer und Entwickler nützen das
          Google-Webservice BigQuery, um große Datenmengen untersuchen und bewegen zu können.</p>
        <p>Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen wie
          Spracheinstellungen, IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und Name des Browsers
          automatisch an die Google-Server übertragen werden. Ob diese Daten auch gespeichert werden, ist nicht klar
          feststellbar bzw. wird von Google nicht eindeutig kommuniziert.</p>
        <h3 >Wie lange und wo werden die Daten gespeichert?</h3>
        <p>Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen Servern, die hauptsächlich außerhalb der
          EU angesiedelt sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu nutzen. Ein
          Stylesheet ist eine Formatvorlage, über die man einfach und schnell z.B. das Design bzw. die Schriftart einer
          Webseite ändern kann.</p>
        <p>Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die Ladezeit von
          Webseiten grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften verweisen,
          werden sie nach dem ersten Besuch zwischengespeichert und erscheinen sofort auf allen anderen später besuchten
          Webseiten wieder. Manchmal aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren, die Abdeckung
          von Sprache zu erhöhen und das Design zu verbessern.</p>
        <h3 >Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
        <p>Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht werden. Die Daten
          werden beim Seitenaufruf automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu können, müssen
          Sie den Google-Support auf <a className="adsimple-121890947"
                                        href="https://support.google.com/?hl=de&amp;tid=121890947">https://support.google.com/?hl=de&amp;tid=121890947</a> kontaktieren.
          Datenspeicherung verhindern Sie in diesem Fall nur, wenn Sie unsere Seite nicht besuchen.</p>
        <p>Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir
          können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite
          rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf <a className="adsimple-121890947"
                                                                                href="https://developers.google.com/fonts/faq?tid=121890947">https://developers.google.com/fonts/faq?tid=121890947</a>.
          Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte Informationen
          über Datenspeicherung sind nicht enthalten. Es ist relativ schwierig, von Google wirklich präzise
          Informationen über gespeicherten Daten zu bekommen.</p>
        <h3 >Rechtsgrundlage</h3>
        <p>Wenn Sie eingewilligt haben, dass Google Fonts eingesetzt werden darf, ist die Rechtsgrundlage der
          entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong
            > Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für
          die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Google Fonts vorkommen kann, dar.
        </p>
        <p>Von unserer Seite besteht zudem ein berechtigtes Interesse, Google Font zu verwenden, um unser Online-Service
          zu optimieren. Die dafür entsprechende Rechtsgrundlage ist <strong >Art. 6 Abs.
            1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Google Font gleichwohl nur ein, soweit Sie eine
          Einwilligung erteilt haben.</p>
        <p>Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des
          Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht.
          Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung
          einhergehen.</p>
        <p>Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen
          Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin
          verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln
          (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen
          sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in
          Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln
          verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau
          einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln
          basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden
          Standardvertragsklauseln u.a. hier: <a className="adsimple-121890947"
                                                 href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de"
                                                 target="_blank"
                                                 rel="noopener">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
        </p>
        <p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auch den
          Standardvertragsklauseln für Google Fonts entsprechen, finden Sie unter <a className="adsimple-121890947"
                                                                                     href="https://business.safety.google/adsprocessorterms/"
                                                                                     target="_blank"
                                                                                     rel="noopener">https://business.safety.google/adsprocessorterms/</a>.
        </p>
        <p>Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auch
          auf <a className="adsimple-121890947"
                 href="https://policies.google.com/privacy?hl=de&amp;tid=121890947">https://www.google.com/intl/de/policies/privacy/</a> nachlesen.
        </p>
        <p>Alle Texte sind urheberrechtlich geschützt.</p>
        <p>Quelle: Erstellt mit dem <a href="https://www.adsimple.at/datenschutz-generator/"
                                       title="Datenschutz Generator von AdSimple für Österreich">Datenschutz
          Generator</a> von AdSimple</p>

      </div>
      <Footer />
    </article>
  );
};

export default Datenschutz;